// --------------------------------------------------
// PRIMARY FONT
// --------------------------------------------------

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-thin.ttf") format('truetype'),
         font("#{$typography-font-name}/#{$typography-font-name}-light.ttf") format('truetype'), // Use light font file as primary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype'); // Use regular font file as secondary fallback
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-thinitalic.ttf") format('truetype'),
         font("#{$typography-font-name}/#{$typography-font-name}-lightitalic.ttf") format('truetype'), // Use light italic font file as primary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype'), // Use regular italic font file as secondary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-light.ttf") format('truetype'), // Use light font file as tertiary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype'); // Use regular font file as quaternary fallback
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-extralight.ttf") format('truetype'),
         font("#{$typography-font-name}/#{$typography-font-name}-light.ttf") format('truetype'), // Use light font file as primary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype'); // Use regular font file as secondary fallback
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-extralightitalic.ttf") format('truetype'),
         font("#{$typography-font-name}/#{$typography-font-name}-lightitalic.ttf") format('truetype'), // Use light italic font file as primary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype'), // Use regular italic font file as secondary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-light.ttf") format('truetype'), // Use light font file as tertiary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype'); // Use regular font file as quaternary fallback
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-light.ttf") format('truetype'),
         font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype'); // Use regular font file as fallback
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-lightitalic.ttf") format('truetype'),
         font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype'), // Use regular italic font file as primary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype'); // Use regular font file as secondary fallback
    font-weight: 300;
    font-style: italic;
}

@font-face {
   font-family: '#{$typography-font-name}';
   src: font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype');
   font-weight: 400;
   font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-italic.ttf") format('truetype'),
         font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype'); // Use regular font file as fallback
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-medium.ttf") format('truetype'),
         font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype'); // Use regular font file as fallback
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-mediumitalic.ttf") format('truetype'),
         font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype'), // Use regular italic font file as primary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype'); // Use regular font file as secondary fallback
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-semibold.ttf") format('truetype'),
         font("#{$typography-font-name}/#{$typography-font-name}-bold.ttf") format('truetype'),  // Use bold font file as primary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype'); // Use regular font file as secondary fallback
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-semibolditalic.ttf") format('truetype'),
         font("#{$typography-font-name}/#{$typography-font-name}-bolditalic.ttf") format('truetype'),  // Use bold italic font file as primary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-italic.ttf") format('truetype'), // Use regular italic font file as secondary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-bold.ttf") format('truetype'),  // Use bold font file as tertiary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype'); // Use regular font file as quaternary fallback
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-bold.ttf") format('truetype'),
         font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype'); // Use regular font file as fallback
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-bolditalic.ttf") format('truetype'),
         font("#{$typography-font-name}/#{$typography-font-name}-italic.ttf") format('truetype'), // Use regular italic font file as primary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype'); // Use regular font file as secondary fallback
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-extrabold.ttf") format('truetype'),
         font("#{$typography-font-name}/#{$typography-font-name}-bold.ttf") format('truetype'),  // Use bold font file as primary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype'); // Use regular font file as secondary fallback
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-extrabolditalic.ttf") format('truetype'),
         font("#{$typography-font-name}/#{$typography-font-name}-bolditalic.ttf") format('truetype'),  // Use bold italic font file as primary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-italic.ttf") format('truetype'), // Use regular italic font file as secondary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-bold.ttf") format('truetype'),  // Use bold font file as tertiary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype'); // Use regular font file as quaternary fallback
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-black.ttf") format('truetype'),
         font("#{$typography-font-name}/#{$typography-font-name}-bold.ttf") format('truetype'),  // Use bold font file as primary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype'); // Use regular font file as secondary fallback
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-blackitalic.ttf") format('truetype'),
         font("#{$typography-font-name}/#{$typography-font-name}-bolditalic.ttf") format('truetype'),  // Use bold italic font file as primary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-italic.ttf") format('truetype'), // Use regular italic font file as secondary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-bold.ttf") format('truetype'),  // Use bold font file as tertiary fallback
         font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype'); // Use regular font file as quaternary fallback
    font-weight: 900;
    font-style: italic;
}

// --------------------------------------------------
// SECONDARY FONT
// --------------------------------------------------

@font-face {
    font-family: '#{$typography-font-name2}';
    src: font("#{$typography-font-name2}/#{$typography-font-name2}-thin.ttf") format('truetype'),
         font("#{$typography-font-name2}/#{$typography-font-name2}-light.ttf") format('truetype'), // Use light font file as primary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-regular.ttf") format('truetype'); // Use regular font file as secondary fallback
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name2}';
    src: font("#{$typography-font-name2}/#{$typography-font-name2}-thinitalic.ttf") format('truetype'),
         font("#{$typography-font-name2}/#{$typography-font-name2}-lightitalic.ttf") format('truetype'), // Use light italic font file as primary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-regular.ttf") format('truetype'), // Use regular italic font file as secondary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-light.ttf") format('truetype'), // Use light font file as tertiary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-regular.ttf") format('truetype'); // Use regular font file as quaternary fallback
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: '#{$typography-font-name2}';
    src: font("#{$typography-font-name2}/#{$typography-font-name2}-extralight.ttf") format('truetype'),
         font("#{$typography-font-name2}/#{$typography-font-name2}-light.ttf") format('truetype'), // Use light font file as primary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-regular.ttf") format('truetype'); // Use regular font file as secondary fallback
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name2}';
    src: font("#{$typography-font-name2}/#{$typography-font-name2}-extralightitalic.ttf") format('truetype'),
         font("#{$typography-font-name2}/#{$typography-font-name2}-lightitalic.ttf") format('truetype'), // Use light italic font file as primary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-regular.ttf") format('truetype'), // Use regular italic font file as secondary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-light.ttf") format('truetype'), // Use light font file as tertiary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-regular.ttf") format('truetype'); // Use regular font file as quaternary fallback
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: '#{$typography-font-name2}';
    src: font("#{$typography-font-name2}/#{$typography-font-name2}-light.ttf") format('truetype'),
         font("#{$typography-font-name2}/#{$typography-font-name2}-regular.ttf") format('truetype'); // Use regular font file as fallback
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name2}';
    src: font("#{$typography-font-name2}/#{$typography-font-name2}-lightitalic.ttf") format('truetype'),
         font("#{$typography-font-name2}/#{$typography-font-name2}-regular.ttf") format('truetype'), // Use regular italic font file as primary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-regular.ttf") format('truetype'); // Use regular font file as secondary fallback
    font-weight: 300;
    font-style: italic;
}

@font-face {
   font-family: '#{$typography-font-name2}';
   src: font("#{$typography-font-name2}/#{$typography-font-name2}-regular.ttf") format('truetype');
   font-weight: 400;
   font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name2}';
    src: font("#{$typography-font-name2}/#{$typography-font-name2}-italic.ttf") format('truetype'),
         font("#{$typography-font-name2}/#{$typography-font-name2}-regular.ttf") format('truetype'); // Use regular font file as fallback
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: '#{$typography-font-name2}';
    src: font("#{$typography-font-name2}/#{$typography-font-name2}-medium.ttf") format('truetype'),
         font("#{$typography-font-name2}/#{$typography-font-name2}-regular.ttf") format('truetype'); // Use regular font file as fallback
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name2}';
    src: font("#{$typography-font-name2}/#{$typography-font-name2}-mediumitalic.ttf") format('truetype'),
         font("#{$typography-font-name2}/#{$typography-font-name2}-regular.ttf") format('truetype'), // Use regular italic font file as primary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-regular.ttf") format('truetype'); // Use regular font file as secondary fallback
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: '#{$typography-font-name2}';
    src: font("#{$typography-font-name2}/#{$typography-font-name2}-semibold.ttf") format('truetype'),
         font("#{$typography-font-name2}/#{$typography-font-name2}-bold.ttf") format('truetype'),  // Use bold font file as primary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-regular.ttf") format('truetype'); // Use regular font file as secondary fallback
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name2}';
    src: font("#{$typography-font-name2}/#{$typography-font-name2}-semibolditalic.ttf") format('truetype'),
         font("#{$typography-font-name2}/#{$typography-font-name2}-bolditalic.ttf") format('truetype'),  // Use bold italic font file as primary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-italic.ttf") format('truetype'), // Use regular italic font file as secondary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-bold.ttf") format('truetype'),  // Use bold font file as tertiary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-regular.ttf") format('truetype'); // Use regular font file as quaternary fallback
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: '#{$typography-font-name2}';
    src: font("#{$typography-font-name2}/#{$typography-font-name2}-bold.ttf") format('truetype'),
         font("#{$typography-font-name2}/#{$typography-font-name2}-regular.ttf") format('truetype'); // Use regular font file as fallback
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name2}';
    src: font("#{$typography-font-name2}/#{$typography-font-name2}-bolditalic.ttf") format('truetype'),
         font("#{$typography-font-name2}/#{$typography-font-name2}-italic.ttf") format('truetype'), // Use regular italic font file as primary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-regular.ttf") format('truetype'); // Use regular font file as secondary fallback
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: '#{$typography-font-name2}';
    src: font("#{$typography-font-name2}/#{$typography-font-name2}-extrabold.ttf") format('truetype'),
         font("#{$typography-font-name2}/#{$typography-font-name2}-bold.ttf") format('truetype'),  // Use bold font file as primary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-regular.ttf") format('truetype'); // Use regular font file as secondary fallback
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name2}';
    src: font("#{$typography-font-name2}/#{$typography-font-name2}-extrabolditalic.ttf") format('truetype'),
         font("#{$typography-font-name2}/#{$typography-font-name2}-bolditalic.ttf") format('truetype'),  // Use bold italic font file as primary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-italic.ttf") format('truetype'), // Use regular italic font file as secondary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-bold.ttf") format('truetype'),  // Use bold font file as tertiary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-regular.ttf") format('truetype'); // Use regular font file as quaternary fallback
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: '#{$typography-font-name2}';
    src: font("#{$typography-font-name2}/#{$typography-font-name2}-black.ttf") format('truetype'),
         font("#{$typography-font-name2}/#{$typography-font-name2}-bold.ttf") format('truetype'),  // Use bold font file as primary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-regular.ttf") format('truetype'); // Use regular font file as secondary fallback
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name2}';
    src: font("#{$typography-font-name2}/#{$typography-font-name2}-blackitalic.ttf") format('truetype'),
         font("#{$typography-font-name2}/#{$typography-font-name2}-bolditalic.ttf") format('truetype'),  // Use bold italic font file as primary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-italic.ttf") format('truetype'), // Use regular italic font file as secondary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-bold.ttf") format('truetype'),  // Use bold font file as tertiary fallback
         font("#{$typography-font-name2}/#{$typography-font-name2}-regular.ttf") format('truetype'); // Use regular font file as quaternary fallback
    font-weight: 900;
    font-style: italic;
}