// --------------------------------------------------
// ANIMATIONS
// --------------------------------------------------

@keyframes fade-in {
    0% {
        opacity: 0;
        transform: translateY(0.4rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(0.4rem);
    }
}

@keyframes fade-in-and-out {
    0%,
    100% {
        opacity: 0;
        transform: translateY(0.4rem);
    }
    33.33%,
    66.66% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes colorize {
    100% {
        @include star-icon-colors--filled;
    }
}

@keyframes pulse {
    0%,
    100% {
        transform: scale(1) translateY(0);
    }
    50% {
        transform: scale(1.4) translateY(-0.4rem);
    }
}

@keyframes bounce {
    0%,
    50%,
    100% {
        transform: scaleY(1) translateY(0) rotate(0deg);
    }
    25% {
        transform: scaleY(1.1) translateY(-1rem) rotate(-8deg);
    }
    75% {
        transform: scaleY(1.05) translateY(-0.5rem) rotate(4deg);
    }
}

// --------------------------------------------------
// MIXINS
// --------------------------------------------------

@mixin star-wrapper-animation($delay) {
     animation: fade-out 500ms ease-in-out $delay forwards;
}

@mixin diamond-wrapper-animation($delay) {
    animation: fade-in 500ms ease-in-out $delay forwards;
}

@mixin coin-animation($delay) {
    animation: fade-in-and-out 1500ms ease-in-out $delay;
}

@mixin star-animation($delay) {
    animation: pulse 500ms ease-in-out $delay;

    svg {
        animation: colorize 250ms ease-in-out $delay forwards;
    }
}

@mixin diamond-animation($delay) {
    animation: bounce 500ms ease-in-out $delay;
}

// --------------------------------------------------
// STYLE
// --------------------------------------------------

app-end-screen-score {

    .end-screen-score {
        @include g-1;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;

        .end-screen-score__star-wrapper,
        .end-screen-score__diamond-wrapper {
            @include g-1;
            display: flex;
            flex-direction: column;
            align-items: center;

            .end-screen-score__coin {
                @include g-1;
                display: flex;
                align-items: center;
                visibility: hidden;
                opacity: 0;

                &.end-screen-score__coin--visible {
                    visibility: visible;
                }

                .end-screen-score__coin-count {
                    @include button-font;
                    line-height: 1;
                    transform: translateY(0.2rem);
                }

                .end-screen-score__coin-icon {
                    flex: none;
                    width: 1.5rem;
                    height: auto;
                    aspect-ratio: 1 / 1;

                    svg {
                        width: 100%;
                        min-width: unset;
                        height: auto;
                        min-height: unset;
                    }
                }
            }
        }

        .end-screen-score__star-wrapper {
            grid-row: 1 / span 1;

            .end-screen-score__star-icon {
                flex: none;
                width: 3rem;
                height: auto;

                svg {
                    @include star-icon-colors;
                    width: 100%;
                    min-width: unset;
                    height: auto;
                    min-height: unset;
                }
            }

            &:nth-of-type(1) {
                grid-column: 1 /span 1;
            }

            &:nth-of-type(2) {
                grid-column: 2 /span 1;
            }

            &:nth-of-type(3) {
                grid-column: 3 /span 1;
            }
        }

        .end-screen-score__diamond-wrapper {
            grid-column: 1 /span 3;
            grid-row: 1 / span 1;
            opacity: 0;

            .end-screen-score__diamond-icon {
                flex: none;
                width: 8rem;
                height: auto;

                svg {
                    width: 100%;
                    min-width: unset;
                    height: 100%;
                    min-height: unset;
                }
            }
        }

        &.end-screen-score--reward-count-1,
        &.end-screen-score--reward-count-2,
        &.end-screen-score--reward-count-3,
        &.end-screen-score--reward-count-4 {

            .end-screen-score__star-wrapper:nth-of-type(1) {

                .end-screen-score__coin--visible {
                    @include coin-animation(1000ms); // Animation ends at 2500ms
                }

                .end-screen-score__star-icon {
                    @include star-animation(500ms); // Animation ends at 1000ms
                }
            }
        }

        &.end-screen-score--reward-count-2,
        &.end-screen-score--reward-count-3,
        &.end-screen-score--reward-count-4 {

            .end-screen-score__star-wrapper:nth-of-type(2) {

                .end-screen-score__coin--visible {
                    @include coin-animation(1750ms); // Animation ends at 3250ms
                }

                .end-screen-score__star-icon {
                    @include star-animation(1250ms); // Animation ends at 1750ms
                }
            }
        }

        &.end-screen-score--reward-count-3,
        &.end-screen-score--reward-count-4 {

            .end-screen-score__star-wrapper:nth-of-type(3) {

                .end-screen-score__coin--visible {
                    @include coin-animation(2500ms); // Animation ends at 4000ms
                }

                .end-screen-score__star-icon {
                    @include star-animation(2000ms); // Animation ends at 2500ms
                }
            }
        }

        &.end-screen-score--reward-count-4 {

            .end-screen-score__star-wrapper {
                @include star-wrapper-animation(3500ms); // Animation ends at 4000ms
            }

            .end-screen-score__diamond-wrapper {
                @include diamond-wrapper-animation(4000ms); // Animation ends at 4500ms

                .end-screen-score__coin--visible {
                    @include coin-animation(4750ms); // Animation ends at 6250ms
                }

                .end-screen-score__diamond-icon {
                    @include diamond-animation(4250ms); // Animation ends at 4750ms
                }
            }
        }
    }
}