app-embedded-activities {

    > * { // Matches every typology
        overflow: hidden !important; // Needed to display app-activities-feedback properly
        position: relative; // Needed to display app-activities-feedback properly

        .mat-mdc-card {

            .mat-mdc-card-header {
                @include pt-3;
            }

            .mat-mdc-card-content {}

            .mat-mdc-card-footer {}
        }
    }
}