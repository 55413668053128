app-activities-feedback {
    $activities-feedback-border-width: 2px;
    display: contents;

    .activities-feedback {
        @include g-3;
        @include px-3;
        @include pt-2;
        @include pb-3;
        position: absolute;
        z-index: 3;
        bottom: 0;
        left: 0;
        right: 0;
        display: grid;
        justify-items: stretch;
        grid-template-columns: repeat(2, auto);
        background-color: var(--discoveredanswerbackground, #006AA6);
        color: var(--white, #FFF);
        border-top: $activities-feedback-border-width solid var(--discoveredanswerstroke, #CEE1EC);
        box-shadow: 0px -30px 50px 0px rgba(255, 255, 255, 0.3);
        font-size: 1.6rem;
        font-weight: 700;
        transition: transform 200ms ease-in-out;

        .activities-feedback__toggle-button {
            position: absolute;
            top: 0;
            left: 50%;
            margin-inline: auto;
            padding-top: 1.5rem;
            padding-inline: 2rem;
            background-color: inherit;
            color: white;
            border-top: $activities-feedback-border-width solid var(--discoveredanswerstroke, #CEE1EC);
            border-right: $activities-feedback-border-width solid var(--discoveredanswerstroke, #CEE1EC);
            border-bottom: unset;
            border-left: $activities-feedback-border-width solid var(--discoveredanswerstroke, #CEE1EC);
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            transform: translate(-50%, -100%);

            .activities-feedback__toggle-icon {
                flex: none;
                width: 1.5rem;
                min-width: unset;
                height: auto;
                min-height: unset;
                transition: transform 200ms ease-in-out;

                svg {
                    width: 100%;
                    min-width: unset;
                    height: auto;
                    min-height: unset;
                }
            }
        }

        .activities-feedback__short-feedback {
            @include g-2;
            grid-column: 1 /span 1;
            grid-row: 1 / span 1;
            justify-self: start;
            display: flex;
            align-items: center;

            .activities-feedback__short-feedback-icon {
                flex: none;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 3.5rem;
                min-width: unset;
                height: auto;
                min-height: unset;
                aspect-ratio: 1;
                background-color: var(--discoveredanswerstroke, #CEE1EC);
                color: var(--discoveredanswerbackground, #006AA6);
                border-radius: 50%;

                svg {
                    width: 85%;
                    min-width: unset;
                    height: auto;
                    min-height: unset;
                }
            }

            .activities-feedback__short-feedback-text {
                margin: unset;
            }
        }

        .activities-feedback__action-button {
            @include feedback-button;
            grid-column: 2 /span 1;
            grid-row: 1 / span 1;
            justify-self: end;

            @include media-breakpoint(gt-sm) { // Greater than 960px
                min-width: 15rem;
            }

            .activities-feedback__action-label {
                display: none;

                @include media-breakpoint(gt-xs) { // Greater than 600px
                    display: initial;
                }
            }

            .activities-feedback__action-icon {}
        }

        // .activities-feedback__long-feedback {
        //     grid-column: 1 /span 2;
        //     grid-row: 2 / span 1;
        // }

        &.correct {
            background-color: var(--goodanswerbackground, #027F77);
            border-color: var(--goodanswerstroke, #009E94);

            .activities-feedback__toggle-button {
                border-color: inherit;
            }

            .activities-feedback__short-feedback {

                .activities-feedback__short-feedback-icon {
                    background-color: var(--goodanswerstroke, #009E94);
                    color: white;
                }
            }

            .activities-feedback__action-button {
                color: var(--goodanswerstroke, #009E94);
                border-color: var(--goodanswerstroke, #009E94);
                box-shadow: 0px 4px 0px 0px var(--goodanswerstroke, #009E94);
            }
        }

        &.wrong {
            background-color: var(--badanswerbackground, #000A10);
            border-color: var(--badanswerstroke, #52758E);

            .activities-feedback__toggle-button {
                border-color: inherit;
            }

            .activities-feedback__short-feedback {

                .activities-feedback__short-feedback-icon {
                    background-color: var(--badanswerstroke, #52758E);
                    color: white;
                }
            }

            .activities-feedback__action-button {
                color: var(--badanswerbackground, #000A10);
                border-color: var(--badanswerstroke, #52758E);
                box-shadow: 0px 4px 0px 0px var(--badanswerstroke, #52758E);
            }
        }

        &.activities-feedback--folded {
            transform: translateY(calc(100% - $spacing-2));

            .activities-feedback__toggle-button {

                .activities-feedback__toggle-icon {
                    transform: rotate(-180deg);
                }
            }
        }
    }
}