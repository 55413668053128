// --------------------------------------------------
// BACKGROUND COVER
// --------------------------------------------------

%background-cover-base {
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        bottom: 0;
        right: 0;
        background-size: cover;
    }
}

%background-cover {
    @extend %background-cover-base;
    &::before {
        background-image: image("backgrounds/carte-londre.png");
    }
}

%background-cover-mobile {
    @extend %background-cover-base;
    &::before {
        background-image: image("backgrounds/carte-londre-mobile.png");
    }
}

// --------------------------------------------------
// CITY MAP BACKGROUND
// --------------------------------------------------

@mixin city-map-background($mobile-background-url, $desktop-background-url) {

    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        inset: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(180deg, rgba(75, 0, 28, 0.15) 0px, transparent 20rem),
                          url($mobile-background-url);
        background-size: cover;
        background-repeat: repeat-y;
    }

    @media (min-width: 38.5rem) {

        &::before {
            background-repeat: repeat;
            background-image: linear-gradient(180deg, rgba(75, 0, 28, 0.15) 0px, transparent 20rem),
                              url($desktop-background-url);
        }
    }
}

// London map background
@mixin london-map-background {
    @include city-map-background(
        '/assets/langues_app/images/backgrounds/carte-londre-mobile.png',
        '/assets/langues_app/images/backgrounds/carte-londre.png'
    );
}

// Madrid map background
@mixin madrid-map-background {
    @include city-map-background(
        '/assets/langues_app/images/backgrounds/carte-madrid-mobile.png',
        '/assets/langues_app/images/backgrounds/carte-madrid.png'
    );
}

// Adaptative city map background (London/Madrid) based on chosen language (english/spanish)
@mixin adaptive-city-map-background {

    &.english--background {
        @include london-map-background;
    }

    &.spanish--background {
        @include madrid-map-background;
    }
}

// --------------------------------------------------
// CITY MAP BACKGROUND WITH BLUR FILTER
// --------------------------------------------------

@mixin city-map-background--blurred($mobile-background-url, $desktop-background-url) {
    @include city-map-background($mobile-background-url, $desktop-background-url);

    &::before {
        z-index: -2;
    }

    &::after {
        content: "";
        position: absolute;
        z-index: -1;
        inset: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(2px);
    }
}

// London map background with blur filter
@mixin london-map-background--blurred {
    @include city-map-background--blurred(
        '/assets/langues_app/images/backgrounds/carte-londre-mobile.png',
        '/assets/langues_app/images/backgrounds/carte-londre.png'
    );
}

// Madrid map background with blur filter
@mixin madrid-map-background--blurred {
    @include city-map-background--blurred(
        '/assets/langues_app/images/backgrounds/carte-madrid-mobile.png',
        '/assets/langues_app/images/backgrounds/carte-madrid.png'
    );
}

// Adaptative city map background (London/Madrid) based on chosen language (english/spanish) with blur filter
@mixin adaptive-city-map-background--blurred {

    &.english--background {
        @include london-map-background--blurred;
    }

    &.spanish--background {
        @include madrid-map-background--blurred;
    }
}