/*
app
    mat-sidenav-container ___________________________________________________
        |   mat-sidenav     |           mat-sidenav-content             |
        |                   |               content                     |
        |                   |         ****  @modules  ****              |
        |                   |                                           |
        |                   |                                           |
    _________________________________________________________________________
*/
mat-sidenav{
    width: 100%;
    @include media-breakpoint-up('sm') {
        max-width: 396px;
    }
}

mat-sidenav-content {
    // Layout
    // @include pt-2; // marge mobiles
    // @include px-2; // marge mobiles
    // padding réduit en desktop
    //@include media-breakpoint-up('sm') {
    //    @include pt-2; // marge supérieure réduite en desktop
    //}
    > .content {
        // les éléments enfants doivent pouvoir exploiter toute la largeur pour des fonds ou autres et notamment en mobile first
        // @include px-2;
    }
    &.mat-drawer-content {
        // Layout
        display: flex;
        flex-direction: column; // organisation verticale
        flex-wrap: nowrap;
        justify-content: normal;
        align-items: normal;
    }
}

.bem-mat-sidenav-content__content { // remplace > .content (trop générique)
    // <router-outlet></router-outlet>
    // ****  @modules  ****

    // Layout
    display: flex;
    flex-grow: 1; // occupe l'espace vertical disponible (PAS DE height 100% !)
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
    // Style
}

.bem-mat-sidenav-content-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    @include pb-2;
    h2 {
        @include h1-font;
        word-wrap: anywhere;
        text-transform: uppercase;
        color: var(--tertiarylabel);
    }
    p{
        @include caption-font;
        color: var(--tertiarylabel);
    }
}

mat-sidenav-container .mat-drawer {
    border-radius: 0 0 15px 0;
    margin: 0 0 60px 0;
    @include media-breakpoint-up('sm') {
        border-radius: 0 15px 15px 0;
        margin: 30px 0;
    }
}