app-progress {

    .progress {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        @include g-1;
        @include button-font;
        line-height: 1 !important;

        .progress__username {}

        .progress__data {
            display: flex;
            align-items: stretch;
            border: 2px solid var(--text, #006AA6);
            border-radius: 6px;

            .progress__level,
            .progress__coins {
                display: flex;
                align-items: center;
                @include py-1;
                @include px-2;

                span {
                    transform: translateY(0.2rem);
                }
            }

            .progress__level {
                background-color: #CEE1EC;
                border-top-left-radius: inherit;
                border-bottom-left-radius: inherit;
            }

            .progress__coins {
                @include g-1;
                color: var(--white, #FFF);
                background-color: var(--text, #006AA6);

                .mat-icon {
                    flex: none;
                    width: 1.4rem;
                    height: auto;
                    aspect-ratio: 1 / 1;

                    svg {
                        width: 100%;
                        min-width: unset;
                        height: 100%;
                        min-height: unset;
                    }
                }
            }
        }
    }
}