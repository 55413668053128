video {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height: 50vh;
}

app-shared-video {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}