@use 'sass:math';

// --------------------------------------------------
// PATH FUNCTIONS
// --------------------------------------------------

/**
 * /assets/<instance>/<module>/<type>/nom_asset.ext
 * Les assets peuvent être organisés par modules et appelé depuis le css (background) ou depuis le html d'un composant.
*/

@function asset($type, $file, $module: null) {
    @return if($module != null, url($asset-base-path + '/' + $module + '/' + $type + '/' + $file), url($asset-base-path + '/' + $type + '/' + $file));
}

@function image($file, $module: null) {
    @return asset('images', $file, $module)
}

@function icon($file, $module: null) {
    @return asset('icons', $file, $module);
}

@function font($file, $module: null) {
    @return asset('fonts', $file, $module);
}

// --------------------------------------------------
// NUMERIC FUNCTIONS
// --------------------------------------------------

@function round-number($number, $decimals: 2) {
    $n: 1;
    @if $decimals > 0 {
        @for $i from 1 through $decimals {
            $n: $n * 10;
        }
    }
    @return math.div(math.round($number * $n), $n);
}

@function convert-px-to-rem($px) {
    $rems: math.div($px, $root-em) * 1rem;
    @return $rems;
}

@function set-fluid-scale($min-size, $max-size, $min-breakpoint: 600px, $max-breakpoint: 1920px, $unit: vw) {
    $slope: math.div($max-size - $min-size, $max-breakpoint - $min-breakpoint);
    $slope-to-unit: round-number($slope * 100);
    $intercept-rem: round-number(convert-px-to-rem($min-size - $slope * $min-breakpoint));
    $min-size-rem: round-number(convert-px-to-rem($min-size));
    $max-size-rem: round-number(convert-px-to-rem($max-size));
    @return clamp(#{$min-size-rem}, #{$slope-to-unit}#{$unit} + #{$intercept-rem}, #{$max-size-rem});
}
