app-launch-button {

    button.launch-button {
        @include py-2;
        @include px-4;
        @include g-2;
        display: flex;
        justify-content: center;
        color: white;
        background-color: var(--goodanswerstroke, #009E94);
        border-color: var(--goodanswerbackground, #027F77);
        border-radius: 24px;
        box-shadow: 0px 8px 0px 0px var(--goodanswerbackground, #027F77),
                    0px 92px 126px 75px rgba(var(--goodanswerstroke-rgb), 0.3);
        font-size: #{set-fluid-scale(35px, 45px)};

        &:active {
            box-shadow: 0px 8px 0px 0px transparent,
                        0px 92px 126px 75px rgba(var(--goodanswerstroke-rgb), 0.3) !important;
        }

        .launch-button__label {
            font-weight: 900;
            letter-spacing: 0.2rem;
            text-transform: uppercase;
            transform: translateY(0.15em);
        }

        .launch-button__play-icon {
            flex: none;
            width: auto;
            height: #{set-fluid-scale(35px, 45px)};
            aspect-ratio: 1 / 1;

            svg {
                width: 100%;
                min-width: unset;
                height: 100%;
                min-height: unset;
                transform: scale(1.6);
            }
        }

        .launch-button__spinner {

            circle {
                stroke: $default-font-contrast-color;
            }
        }
    }
}