app-fill-in-blanks-child {
    flex: 3;
    display: flex;


    // Font
    @include button-font;
    // Style
    color: var(--tertiarylabel, #006AA6);



    .container {
        flex: 1;
        display: flex;
        justify-content: center;
        padding: 20px 10px;

        border-radius: 16px;
        border: 2px solid var(--progression-border, #CEE1EC);
        background: var(--progression-background, #E5EBF1);


        input {
            background-color: transparent;
        }
    }

}