app-dictionary {
    .loader {
        margin: 2px;
    }

    > .container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        overflow-y: auto; // need for infinite scroll not remove it
    }

    > .container audio {
        display: none;
    }

    app-dictionary-card {
        display: flex;
        justify-content: center;
        width: 40%;
        margin: 30px auto auto;
        position: relative;

        button.record {
            @include primary-button;
            padding: 0;
            padding-inline: inherit !important;
            justify-content: center !important;
            width: 40px;
            height: 40px;
            position: relative;
            margin-left: -40px;
            right: -5px;
            top: -5px;

            mat-icon {
                justify-content: center;
                align-items: center;
                display: flex;
                margin: auto;
            }
        }

        button.app-dictionary-card__reading .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            
            background: rgba(var(--primaryborder-rgb), 0.25);
        }

        button {
            gap: 0;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0;
            width: 100%;
            max-width: 300px;
            align-items: inherit;
        }

        .app-dictionary-card__not-reading {
            border: 2px solid var(--primaryborder, #4B001C);
            box-shadow: 0 4px 0 0 #4B001C;
        }

        .app-dictionary-card__reading {
            border: 2px solid var(--primarybackground, #B10745);
        }

        span {
            width: 100%;
            border-radius: 0 0 8px 8px;
            padding: 5px;
            height: auto;
            min-height: 40px;
            justify-content: center;
            align-items: center;
            display: flex;
            color: white;
        }

        .app-dictionary-card__not-reading span {
            border-top: 2px solid var(--primaryborder, #4B001C);
            background: var(--primarybackground, #B10745);
        }

        .app-dictionary-card__reading span {
            border-top: 2px solid var(--primarybackground, #B10745);
            background: var(--primaryborder, #4B001C);
        }

        img {
            border-radius: 9px 9px 0 0;
        }

        mat-icon.play {
            position: absolute;
            top: -10px;
            left: -10px;
            background: white;
            border-radius: 50%;
            border: 2px solid;
            
            color: var(--primaryborder, #4B001C);
            border-color: var(--primarybackground, #B10745);
            
            svg {
                width: 20px;
                height: 20px;
                min-width: 20px;
                min-height: 20px;
            }
        }
    }

    @media (min-width: 1000px) {
        > .container {
            padding-left: 15%;
            padding-right: 15%;
        }
    }
}

app-dictionary-keyboard {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    justify-content: center;

    button {
        min-width: 30px;
        justify-content: center;
        align-items: center;
        flex: 1 0 10%;
        max-width: calc(100% / 10);


        span.upstair {
            position: absolute;
            margin-bottom: 5px;
        }

        span.downstair {
            margin-top: 5px;
            margin-left: 2px;
        }
    }

    // same effect for disabled button than active in this case of use
    button:disabled {
        color: var(--secondarylabel);
        background: var(--tertiarybackground, #F7E6EC);
        border: 2px solid var(--primarybackground, #B10745);
        box-shadow: none;
        transform: translateY(4px);
    }
}