app-what-is-missing,
app-what-is-missing-audio {

    mat-card.activities-card {

        .mat-mdc-card-content {}

        .mat-mdc-card-footer {
            flex-direction: column;
            gap: 20px;

            .available-answers {

                button {

                    span::first-letter {
                        text-transform: uppercase;
                    }
                }
            }

            audio {
                display: none; // Do not display audio element
            }
        }

        &.hidden-answers {
            @include media-breakpoint-up('sm') {
                flex-direction: row;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                align-content: flex-start;

                mat-card-header.mat-mdc-card-header {
                    flex: 1;
                    min-width: 100%;
                }
                mat-card-content.mat-mdc-card-content {
                    flex: 5;
                }
                mat-card-footer.mat-mdc-card-footer {
                    flex: 4;
                }
            }
        }
    }
}