@use '../../../../../../node_modules/@angular/material/index' as mat;

// --------------------------------------------------
// H1
// --------------------------------------------------

@mixin h1-font {
    font: mat.get-theme-typography($theme, headline-5); // Emit all CSS styles of the headline-5 typography level
}

@mixin h1-font-family {
    font-family: mat.get-theme-typography($theme, headline-5, font-family);
}

@mixin h1-font-size {
    font-size: mat.get-theme-typography($theme, headline-5, font-size);
}

@mixin h1-font-weight {
    font-weight: mat.get-theme-typography($theme, headline-5, font-weight);
}

@mixin h1-letter-spacing {
    letter-spacing: mat.get-theme-typography($theme, headline-5, letter-spacing);
}

@mixin h1-line-height {
    line-height: mat.get-theme-typography($theme, headline-5, line-height);
}

// --------------------------------------------------
// H2
// --------------------------------------------------

@mixin h2-font {
    font: mat.get-theme-typography($theme, headline-6); // Emit all CSS styles of the headline-6 typography level
}

@mixin h2-font-family {
    font-family: mat.get-theme-typography($theme, headline-6, font-family);
}

@mixin h2-font-size {
    font-size: mat.get-theme-typography($theme, headline-6, font-size);
}

@mixin h2-font-weight {
    font-weight: mat.get-theme-typography($theme, headline-6, font-weight);
}

@mixin h2-letter-spacing {
    letter-spacing: mat.get-theme-typography($theme, headline-6, letter-spacing);
}

@mixin h2-line-height {
    line-height: mat.get-theme-typography($theme, headline-6, line-height);
}

// --------------------------------------------------
// H3
// --------------------------------------------------

@mixin h3-font {
    font: mat.get-theme-typography($theme, subtitle-1); // Emit all CSS styles of the subtitle-1 typography level
}

@mixin h3-font-family {
    font-family: mat.get-theme-typography($theme, subtitle-1, font-family);
}

@mixin h3-font-size {
    font-size: mat.get-theme-typography($theme, subtitle-1, font-size);
}

@mixin h3-font-weight {
    font-weight: mat.get-theme-typography($theme, subtitle-1, font-weight);
}

@mixin h3-letter-spacing {
    letter-spacing: mat.get-theme-typography($theme, subtitle-1, letter-spacing);
}

@mixin h3-line-height {
    line-height: mat.get-theme-typography($theme, subtitle-1, line-height);
}

// --------------------------------------------------
// H4
// --------------------------------------------------

@mixin h4-font {
    font: mat.get-theme-typography($theme, subtitle-2); // Emit all CSS styles of the subtitle-2 typography level
}

@mixin h4-font-family {
    font-family: mat.get-theme-typography($theme, subtitle-2, font-family);
}

@mixin h4-font-size {
    font-size: mat.get-theme-typography($theme, subtitle-2, font-size);
}

@mixin h4-font-weight {
    font-weight: mat.get-theme-typography($theme, subtitle-2, font-weight);
}

@mixin h4-letter-spacing {
    letter-spacing: mat.get-theme-typography($theme, subtitle-2, letter-spacing);
}

@mixin h4-line-height {
    line-height: mat.get-theme-typography($theme, subtitle-2, line-height);
}

// --------------------------------------------------
// BASE BODY TEXT
// --------------------------------------------------

@mixin body-font {
    font: mat.get-theme-typography($theme, body-1); // Emit all CSS styles of the body-1 typography level
}

@mixin body-font-family {
    font-family: mat.get-theme-typography($theme, body-1, font-family);
}

@mixin body-font-size {
    font-size: mat.get-theme-typography($theme, body-1, font-size);
}

@mixin body-font-weight {
    font-weight: mat.get-theme-typography($theme, body-1, font-weight);
}

@mixin body-letter-spacing {
    letter-spacing: mat.get-theme-typography($theme, body-1, letter-spacing);
}

@mixin body-line-height {
    line-height: mat.get-theme-typography($theme, body-1, line-height);
}

// --------------------------------------------------
// BOLDER BODY TEXT
// --------------------------------------------------

@mixin bold-font {
    font: mat.get-theme-typography($theme, body-2); // Emit all CSS styles of the body-2 typography level
}

@mixin bold-font-family {
    font-family: mat.get-theme-typography($theme, body-2, font-family);
}

@mixin bold-font-size {
    font-size: mat.get-theme-typography($theme, body-2, font-size);
}

@mixin bold-font-weight {
    font-weight: mat.get-theme-typography($theme, body-2, font-weight);
}

@mixin bold-letter-spacing {
    letter-spacing: mat.get-theme-typography($theme, body-2, letter-spacing);
}

@mixin bold-line-height {
    line-height: mat.get-theme-typography($theme, body-2, line-height);
}

// --------------------------------------------------
// SMALLER BODY TEXT
// --------------------------------------------------

@mixin caption-font {
    font: mat.get-theme-typography($theme, caption); // Emit all CSS styles of the caption typography level
}

@mixin caption-font-family {
    font-family: mat.get-theme-typography($theme, caption, font-family);
}

@mixin caption-font-size {
    font-size: mat.get-theme-typography($theme, caption, font-size);
}

@mixin caption-font-weight {
    font-weight: mat.get-theme-typography($theme, caption, font-weight);
}

@mixin caption-letter-spacing {
    letter-spacing: mat.get-theme-typography($theme, caption, letter-spacing);
}

@mixin caption-line-height {
    line-height: mat.get-theme-typography($theme, caption, line-height);
}

// --------------------------------------------------
// BUTTONS
// --------------------------------------------------

@mixin button-font {
    font: mat.get-theme-typography($theme, button); // Emit all CSS styles of the button typography level
}

@mixin button-font-family {
    font-family: mat.get-theme-typography($theme, button, font-family);
}

@mixin button-font-size {
    font-size: mat.get-theme-typography($theme, button, font-size);
}

@mixin button-font-weight {
    font-weight: mat.get-theme-typography($theme, button, font-weight);
}

@mixin button-letter-spacing {
    letter-spacing: mat.get-theme-typography($theme, button, letter-spacing);
}

@mixin button-line-height {
    line-height: mat.get-theme-typography($theme, button, line-height);
}