app-period-list {
    @include adaptive-city-map-background;
    @include g-4;
    @include p-2;
    display: flex;
    flex-direction: column;
    align-items: center;

    .period-list__my-lessons-button {
        align-self: flex-end;
        position: sticky;
        z-index: 2;
        top: 0;

        .period-list__my-lessons-label {
            text-transform: uppercase;
            transform: translateY(0.1em);
        }

        .period-list__my-lessons-count {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25px;
            height: 25px;
            color: white;
            background-color: var(--secondarylabel);
            border-radius: 50%;

            > span {
                transform: translateY(0.1em);
            }
        }
    }

    .period-list__wrapper {
        @include mx-auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        :where(.period-list__period-button) { // Allow to override placeholder style with a simple class selector (:where pseudo-class has 0 specificity)
            @include primary-button;
        }

        .period-list__period-button {
            position: relative;
            flex-direction: column;
            justify-content: flex-start !important;
            align-items: stretch !important;
            gap: unset !important;
            width: 260px;
            height: 120px;
            margin: 10px;
            padding: unset !important;
            box-shadow: 0px 4px 0px 0px var(--primaryborder, #4B001C),
                        0px 19px 14px 0px rgba(var(--primaryborder-rgb), 0.3),
                        0px 49px 34px 0px rgba(var(--primarybackground-rgb), 0.05) !important;

            &:active {
                background-color: var(--primarybackground, #B10745) !important;
                box-shadow: 0px 4px 0px 0px transparent,
                            0px 19px 14px 0px rgba(var(--primaryborder-rgb), 0.3),
                            0px 49px 34px 0px rgba(var(--primarybackground-rgb), 0.05) !important;
            }

            .period-list__period-header {
                display: grid;
                grid-template-columns: 1fr auto;
                grid-template-rows: repeat(2, auto);
                padding: 0 10px 5px 10px;
                background-color: var(--primaryborder);
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;

                .period-list__period-index {
                    grid-column: 2 / span 1;
                    grid-row: 1 / span 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: auto;
                    aspect-ratio: 1 / 1;
                    color: var(--text, #006AA6);
                    background-color: var(--white, #FFF);
                    border: 2px solid var(--primaryborder, #4B001C);
                    border-radius: 8px;
                    font-size: 2.2rem;
                    transform: translateY(-10px);

                    > span {
                        transform: translateY(0.1em);
                    }
                }

                .period-list__period-state {
                    @include g-1;
                    grid-column: 1 / span 1;
                    grid-row: 1 / span 1;
                    display: flex;
                    align-items: center;
                    padding-left: 10px;
                    font-size: 1.4rem;
                    font-weight: 400;

                    &.period-list__period-state--not_started {
                        color: rgba(var(--white-rgb), 0.5)
                    }

                    > span {
                        transform: translateY(0.1em);
                    }
                }

                .period-list__period-progress-bar {
                    grid-column: 1 / span 2;
                    grid-row: 2 / span 1;
                    height: 14px;
                    border-radius: 100px;
                    border: 2px solid rgba(var(--white-rgb), 0.07);
                    background-color: rgba(var(--progressionbackground-rgb), 0.25);

                    .progress-bar {
                        height: 100%;
                        border-radius: 24px;
                        background-color: var(--primarylabel, #FFF);
                        box-shadow: 0 4px 0 0 rgba(var(--progressionbackground-rgb), 0.5) inset;
                        transition-duration: 400ms;
                    }

                    &.period-list__period-progress-bar--filled { // If progress === 100

                        .progress-bar {
                            border: 2px solid var(--goodanswerstroke, #009E94);
                            background-color: var(--goodanswerstroke, #009E94);
                        }
                    }
                }
            }

            .period-list__period-label {
                @include my-auto;
                padding: 10px 20px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                font-size: 1.6rem;
                line-height: 1.2;
                letter-spacing: 1px;
            }
        }
    }

    .period-list__spinner {
        @include mt-auto;
    }

    .period-list__launch-button {
        @include mt-auto;
        @include mb-3;
        position: sticky;
        z-index: 2;
        bottom: $spacing-3;
    }
}

/**
mobile design
 */
@media (max-width: 858px) {

    app-period-list {

        .period-list__wrapper {
            /**
                if screen have no enought width we don't show line
             */
            @media(min-width: 370px) {

                // line  who join cards
                .period-list__period-button--even:nth-last-of-type(n+2)::after {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    background-image: url(/assets/langues_app/images/backgrounds/right-line.png);
                    background-repeat: no-repeat;
                    width: 100%;
                    height: 100%;
                    top: 80px;
                    left: 100%;
                }
                // line  who join cards
                .period-list__period-button--odd:nth-last-of-type(n+2)::before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    background-image: url(/assets/langues_app/images/backgrounds/left-line.png);
                    background-repeat: no-repeat;
                    width: 100%;
                    height: 100%;
                    top: 65px;
                    left: -64px;
                }
            }

            @media(max-width: 370px) {

                .period-list__period-button--even {
                    margin-right: 10px;
                }
            }

            @media(min-width: 371px) {

                .period-list__period-button--even {
                    margin-right: 105px;
                }

                .period-list__period-button--odd {
                    margin-left: 85px;
                }
            }
        }
    }
}

/**
desktop and tablette version
 */
@media (min-width: 859px) {

    app-period-list {

        .period-list__wrapper {
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;

            .period-list__period-button--odd {
                margin-top: 160px;
            }

            // line  who join cards
            .period-list__period-button--even:nth-last-of-type(n+2)::after {
                content: "";
                position: absolute;
                z-index: -1;
                background-image: url(/assets/langues_app/images/backgrounds/left-line.png);
                background-repeat: no-repeat;
                transform: rotate(270deg);
                width: 100%;
                height: 100%;
                left: 57px;
                bottom: 0;
            }

            // line  who join cards
            .period-list__period-button--odd:nth-last-of-type(n+2)::before {
                content: "";
                position: absolute;
                z-index: -1;
                background-image: url(/assets/langues_app/images/backgrounds/right-line.png);
                background-repeat: no-repeat;
                width: 100%;
                transform: rotate(270deg);
                height: 100%;
                top: -185px;
                left: 55px;
            }
        }
    }
}

/**
adjust space beetween in desktop mode to always have five card
 */
app-period-list {

    .period-list__wrapper {

        @media (min-width: 859px) and (max-width: 999px) {

            .period-list__period-button:not(:first-child) {
                margin-left: -130px;
            }
        }

        @media (min-width: 1000px) and (max-width: 1099px) {

            .period-list__period-button:not(:first-child) {
                margin-left: -100px;
            }
        }

        @media (min-width: 1100px) {

            .period-list__period-button:not(:first-child) {
                margin-left: -80px;
            }
        }
    }
}