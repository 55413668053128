app-badge-type-list {
    @include london-map-background--blurred;
    display: flex;
    flex-direction: row !important;
    justify-content: center;

    &::before {
        z-index: -2;
    }

    &::after {
        content: "";
        position: absolute;
        z-index: -1;
        inset: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(2px);
    }

    .badge-type-list__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include g-3;
        width: min(50rem, 50vh);
        min-width: 20rem;
        max-width: 100%;
        height: fit-content;
        @include py-3;
        @include px-2;

        @media (orientation: landscape) {
            flex-direction: row;
            justify-content: center;
            width: 100%;
            min-width: unset;
            max-width: unset;
            min-height: fit-content;
            @include p-4;
        }

        app-animated-canvas {
            overflow: hidden;
            flex: none;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: auto;
            aspect-ratio: 1 / 1;
            background-color: var(--white, #FFF);
            @include r-2;

            @media (orientation: landscape) {
                position: sticky;
                top: $spacing-3;
                flex: auto;
                align-self: flex-start;
                width: 35%;
                min-width: 20rem;
                max-width: min(60rem, calc(100vh - 200px));
            }

            .animated-canvas {
                width: 100%;
                height: 100%;
                transform: scale(1.4);
            }
        }

        .badge-type-list {
            display: flex;
            flex-direction: column;
            @include g-2;
            width: 100%;

            @media (orientation: landscape) {
                flex: auto;
                align-self: center;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-auto-rows: 1fr;
                width: 65%;
                min-width: 40rem;
                max-width: 60rem;
            }

            .badge-type-list__item {
                width: 100%;
                height: auto;
                padding: unset;

                .mdc-list-item__content {
                    padding: unset;

                    .badge-type-list__item-button {
                        @include primary-button;
                        justify-content: space-between;
                        width: 100%;
                        height: auto;
                        @include py-2;
                        @include px-3;
                        text-transform: uppercase;

                        @media (orientation: landscape) {
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: center;
                            @include p-2;
                        }

                        .badge-type-list__item-name {

                            @media (orientation: landscape) {
                                order: 2;
                            }
                        }

                        .badge-type-list__item-icon {
                            flex: initial;
                            width: 3rem;
                            height: auto;
                            aspect-ratio: 1 / 1;

                            @media (orientation: landscape) {
                                order: 1;
                                width: 7rem;
                            }

                            svg {
                                width: 100%;
                                min-width: unset;
                                height: 100%;
                                min-height: unset;
                            }
                        }
                    }
                }
            }
        }
    }
}