app-closing-pop-up {
    
    .closing-pop-up {
        @include g-3;
        display: flex;
        flex-direction: column;
        min-width: min-content;
        max-width: 40rem;
        max-height: calc(90vh - 5rem);

        .closing-pop-up__header {
            @include g-2;
            display: flex;
            flex-direction: column;
            padding-inline: 2rem;

            .closing-pop-up__title {
                @include h1-font;
                text-transform: uppercase;
                font-weight: 900;
            }

            .closing-pop-up__text {
                @include button-font;
            }
        }

        .closing-pop-up__actions {
            @include g-1;
            display: flex;
            flex-direction: column;
            align-items: center;

            .closing-pop-up__skip-button,     
            .closing-pop-up__close-button,       
            .closing-pop-up__resume-button {
                width: 100%;
                line-height: 1.2;

                .closing-pop-up__button-label {
                    transform: translateY(0.1em);
                }

                .closing-pop-up__button-icon {
                    flex: none;
                    width: 3rem;
                    height: auto;
                    aspect-ratio: 1 / 1;

                    svg {
                        width: 100%;
                        min-width: unset;
                        height: 100%;
                        min-height: unset;
                    }
                }
            }

            .closing-pop-up__skip-button {

                .closing-pop-up__button-icon {
                    transform: scaleX(-1);
                }
            }

            .closing-pop-up__resume-button {
                @include primary-button;
            }
        }
    }
}