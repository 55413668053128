app-preview-item-popup {

    .preview-item-popup {
        @include g-2;
        display: flex;
        flex-direction: column;
        min-width: min-content;
        max-height: calc(90vh - 50px);

        .preview-item-popup__content {
            @include g-1;
            display: flex;
            flex-direction: column;

            .preview-item-popup__title {
                text-align: center;
                font-size: #{set-fluid-scale(20px, 24px)};
            }

            .preview-item-popup__purchase-balance {
                @include g-2;
                @include py-1;
                @include px-2;
                @include button-font;
                display: flex;
                justify-content: space-between;
                color: var(--white, #FFF);
                background-color: var(--text, #006AA6);
                border-radius: 12px;
                line-height: 1;

                .preview-item-popup__user-coins,
                .preview-item-popup__badge-price {
                    @include g-1;
                    display: flex;
                    justify-content: space-between;

                    p {
                        transform: translateY(0.2rem);
                    }

                    .mat-icon {
                        flex: none;
                        width: 1.4rem;
                        height: auto;
                        aspect-ratio: 1 / 1;

                        svg {
                            width: 100%;
                            min-width: unset;
                            height: 100%;
                            min-height: unset;
                        }
                    }
                }
            }
        }

        app-animated-canvas {
            overflow: hidden;
            flex: none;
            align-self: center;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-width: 15rem;
            max-width: min(40rem, 35vh);
            height: auto;
            aspect-ratio: 1 / 1;

            .animated-canvas {
                width: 100%;
                height: 100%;
                transform: scale(1.4);
            }
        }

        .preview-item-popup__actions {
            @include g-1;
            display: flex;
            flex-direction: column;

            button {
                @include g-2;
                justify-content: space-between !important;
                line-height: 1 !important;

                &.preview-item-popup__remove-button,
                &.preview-item-popup__add-button {

                    .mat-icon {
                        transform: scale(0.8);
                    }
                }

                &:not(.preview-item-popup__back-button) {
                    @include primary-button;
                }

                span {
                    transform: translateY(0.2rem);
                }
            }
        }
    }
}