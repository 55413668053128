:root {

    // --------------------------------------------------
    // BASE
    // --------------------------------------------------

    --white: rgba(255, 255, 255, 1); // var(--white, #FFF);
    --white-rgb: 255, 255, 255; // rgba(var(--white-rgb), 1);
    --black: rgba(0, 0, 0, 1); // var(--black, #000);
    --appbackground: rgba(251, 245, 247, 1); // var(--appbackground, #FBF5F7);
    --text: rgba(0, 106, 166, 1); // var(--text, #006AA6);

    // --------------------------------------------------
    // PRIMARY / RESPONSE
    // --------------------------------------------------
    
    --primarybackground: rgba(177, 7, 69, 1); // var(--primarybackground, #b10745);
    --primarybackground-rgb: 177, 7, 69; // rgba(var(--primarybackground-rgb), 1);
    --primarybackground--hover: rgba(201, 8, 79, 1); // var(--primarybackground--hover, #C9084F);
    --primarylabel: rgba(255, 255, 255, 1); // var(--primarylabel, #FFF);
    --primarylabel-contrast: var(--primarylabel, #FFF); // var(--primarylabel--contrast, #FFF);
    --primaryborder: rgba(75, 0, 28, 1); // var(--primaryborder, #4B001C);
    --primaryborder-rgb: 75, 0, 28; // rgba(var(--primaryborder-rgb), 0.8);

    // --------------------------------------------------
    // SECONDARY
    // --------------------------------------------------
    
    --secondarybackground: var(--white, #FFF); // var(--secondarybackground, #FFF);
    --secondarybackground--hover: rgba(230, 207, 207, 1); // var(--secondarybackground--hover, #E6CFCF);
    --secondarylabel: rgba(177, 7, 69, 1); // var(--secondarylabel, #B10745);
    --secondaryborder: rgba(177, 7, 69, 1); // var(--secondaryborder, #B10745);

    // --------------------------------------------------
    // TERTIARY
    // --------------------------------------------------
    
    --tertiarybackground: rgba(247, 230, 236, 1); // var(--tertiarybackground, #f7e6ec);
    --tertiarybackground--hover: rgba(255, 242, 242, 1); // var(--tertiarybackground--hover, #FFF2F2);
    --tertiarylabel: rgba(0, 106, 166, 1); // var(--tertiarylabel, #006AA6);
    --tertiaryicon: rgba(177, 7, 69, 1); // var(--tertiaryicon, #B10745);
    --tertiaryborder: rgba(240, 208, 219, 1); // var(--tertiaryborder, #F0D0DB);

    // --------------------------------------------------
    // QUATERNARY
    // --------------------------------------------------
    
    --quaternaireborder: rgba(0, 106, 166, 1); // var(--quaternaireborder, #006AA6);
    --quaternairebackground: rgba(0, 41, 64, 1); // var(--quaternairebackground, #002940);
    --quaternairelabel: rgba(177, 7, 69, 1); // var(--quaternairelabel, #B10745);

    // --------------------------------------------------
    // DISABLED
    // --------------------------------------------------
    
    --textdisabled: rgba(82, 117, 142, 1); // var(--textdisabled, #52758E);
    --textdisabled-rgb: 82, 117, 142; // rgba(var(--textdisabled-rgb), 0.8);
    --disabledstroke: rgba(82, 117, 142, 1); // var(--disabledstroke, #52758E);
    --disabledbackground: var(--appbackground, #FBF5F7); // var(--disabledbackground, #FBF5F7);

    // --------------------------------------------------
    // ANSWER
    // --------------------------------------------------
    
    --goodanswerbackground: rgba(2, 127, 119, 1); // var(--goodanswerbackground, #027F77);
    --goodanswerstroke: rgba(0, 158, 148, 1); // var(--goodanswerstroke, #009E94);
    --goodanswerstroke-rgb: 0, 158, 148; // rgba(var(--goodanswerstroke-rgb), 0.25);
    
    --badanswerbackground: rgba(0, 10, 16, 1); // var(--badanswerbackground, #000A10);
    --badanswerstroke: rgba(82, 117, 142, 1); // var(--badanswerstroke, #52758E);
    
    --discoveredanswerbackground: rgba(0, 106, 166, 1); // var(--discoveredanswerbackground, #006AA6);
    --discoveredanswerstroke: rgba(206, 225, 236, 1); // var(--discoveredanswerstroke, #CEE1EC);

    // --------------------------------------------------
    // PROGRESSION
    // --------------------------------------------------
    
    --progressionbackground: rgba(229, 235, 241, 1); // var(--progressionbackground, #E5EBF1);
    --progressionbackground-rgb: 229, 235, 241; // rgba(var(--progressionbackground-rgb), 1);
    --progressionborder: rgba(206, 225, 236, 1); // var(--progressionborder, #CEE1EC);
    --progressiongood: rgba(0, 158, 148, 1); // var(--progressiongood, #009E94);
    --progressionbad: rgba(0, 10, 16, 1); // var(--progressionbad, #000A10);

    // --------------------------------------------------
    // IONIC TEST
    // --------------------------------------------------

    /** lessons-header **/
    --color-lessons-header: var(--white, #FFF);
    --color-lessons-header-rgb: 255, 255, 255;
    --color-lessons-header-contrast: var(--secondarylabel);
    --color-lessons-header-contrast-rgb: 177, 7, 69;
    --color-lessons-header-shade: #f3e1e4;// // Une version légèrement plus foncée de la couleur de base
    --color-lessons-header-tint: #ffffff; // Une version légèrement plus claire de la couleur de base



    --progression-background: #E5EBF1;
    --progression-border: #CEE1EC;

}