app-multimedia {

    .activities-card {

        .mat-mdc-card-content {
            overflow: auto;
            
            @include media-breakpoint-up('sm') {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
            }

            app-consignes {
                flex: none;
            }
        }
    }
}