> mat-card-content.mat-mdc-card-content {
    overflow: visible; // Required to properly display answer button status icon
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    gap: 20px;
    padding: 0 var(--padding-lateral-viewport);

    @include media-breakpoint-up('sm') {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;
    }

    > app-consignes {

        @include media-breakpoint-up('sm') {
            flex: 5;
            align-items: center;
            max-width: 90vh;

            & + * {
                flex: 4;
                margin-inline: inherit;
                margin-bottom: auto;
                min-width: 40%;
                margin-top: 20px;
            }
        }
    }

    > app-flashcard {
        flex: none;
        width: 100%;
        max-width: 50rem;

        @include media-breakpoint-up('sm') {
            flex: 1;
            width: unset;
            margin: 0 auto;

            & + * {
                flex: 1;
            }
        }
    }
}

mat-card-footer.mat-mdc-card-footer {

    @include media-breakpoint-up('sm') {
        flex: none;
        margin: 0;
        position: relative;
        padding: 0 clamp(10px, 0.76vw + 5.45px, 20px);
    }
}