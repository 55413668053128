// --------------------------------------------------
// HELPERS
// --------------------------------------------------

@mixin button-base( // Set button base style (for elements that just look like buttons)
    $color,
    $background-color,
    $border,
    $box-shadow,
    $icon-color,
    ) {
    margin-bottom: 4px; // Minimum margin needed to display box-shadow properly
    color: $color;
    background-color: $background-color;
    border: $border;
    box-shadow: $box-shadow;

    mat-icon.mat-icon,
    .mdc-label {
        color: $icon-color;
    }
}

@mixin button-extra( // Set button layout and state-related style (for elements that act like buttons)
    $color--hover,
    $background-color--hover,
    $border--hover,
    $color--active,
    $background-color--active,
    $border--active,
    ) {
    @include button-font;
    flex: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    min-height: 3rem;
    padding: 1rem 2rem;
    border-radius: 12px;
    cursor: pointer;
    transition: all 55ms linear;

    &:hover,
    &:focus {
        color: $color--hover;
        background-color: $background-color--hover;
        border: $border--hover;
    }

    &:active,
    &[disabled],
    &.mat-mdc-checkbox-disabled {
        box-shadow: none;
        transform: translateY(4px);
    }

    &:active {
        color: $color--active;
        background-color: $background-color--active;
        border: $border--active;
    }

    &[disabled],
    &.mat-mdc-checkbox-disabled {
        color: var(--disabledstroke, #52758E);
        background-color: var(--disabledbackground, #FBF5F7);
        border: 2px solid var(--disabledstroke, #52758E);
        pointer-events: none;
    }
}

// --------------------------------------------------
// PRIMARY BUTTON (red background & dark red border)
// --------------------------------------------------

@mixin primary-button-base {
    @include button-base(
        $color: var(--primarylabel, #FFF),
        $background-color: var(--primarybackground, #B10745),
        $border: 2px solid var(--primaryborder, #4B001C),
        $box-shadow: 0px 4px 0px 0px var(--primaryborder, #4B001C),
        $icon-color: inherit,
    )
}

@mixin primary-button {
    @include primary-button-base;
    @include button-extra(
        $color--hover: null,
        $background-color--hover: var(--primarybackground--hover, #C9084F),
        $border--hover: null,
        $color--active: var(--primarylabel--contrast, #FFF),
        $background-color--active: var(--primaryborder, #4B001C),
        $border--active: 2px solid var(--primarybackground, #B10745),
    );
}

// --------------------------------------------------
// SECONDARY BUTTON (white background & red border)
// --------------------------------------------------

@mixin secondary-button-base {
    @include button-base(
        $color: var(--secondarylabel, #B10745),
        $background-color: var(--secondarybackground, #FFF),
        $border: 2px solid var(--secondarylabel, #B10745),
        $box-shadow: 0px 4px 0px 0px var(--secondarylabel, #B10745),
        $icon-color: inherit,
    )
}

@mixin secondary-button {
    @include secondary-button-base;
    @include button-extra(
        $color--hover: null,
        $background-color--hover: null,
        $border--hover: null,
        $color--active: null,
        $background-color--active: var(--tertiarybackground, #F7E6EC),
        $border--active: null,
    );
}

// --------------------------------------------------
// TERTIARY BUTTON (pink background & pink border)
// --------------------------------------------------

@mixin tertiary-button-base {
    @include button-base(
        $color: var(--tertiarylabel, #006AA6),
        $background-color: var(--tertiarybackground, #F7E6EC),
        $border: 2px solid var(--tertiaryborder, #F0D0DB),
        $box-shadow: 0px 4px 0px 0px var(--tertiaryborder, #F0D0DB),
        $icon-color: var(--tertiaryicon, #B10745),
    )
}

@mixin tertiary-button {
    @include tertiary-button-base;
    @include button-extra(
        $color--hover: null,
        $background-color--hover: var(--tertiarybackground--hover, #FFF2F2),
        $border--hover: null,
        $color--active: null,
        $background-color--active: var(--tertiaryborder, #F0D0DB),
        $border--active: 2px solid var(--tertiarybackground, #F7E6EC),
    );
}

// --------------------------------------------------
// QUATERNARY BUTTON (white background & blue border)
// --------------------------------------------------

@mixin quaternary-button-base {
    @include button-base(
        $color: var(--quaternairelabel, #B10745),
        $background-color: var(--secondarybackground, #FFF),
        $border: 2px solid var(--quaternaireborder, #006AA6),
        $box-shadow: 0px 4px 0px 0px var(--quaternaireborder, #006AA6),
        $icon-color: inherit,
    )
}

@mixin quaternary-button {
    @include quaternary-button-base;
    @include button-extra(
        $color--hover: var(--white),
        $background-color--hover: var(--quaternaireborder, #006AA6),
        $border--hover: null,
        $color--active: var(--white),
        $background-color--active: var(--quaternaireborder, #006AA6),
        $border--active: null,
    );
}

// --------------------------------------------------
// EXPAND BUTTON
// --------------------------------------------------

@mixin expand-button {
    @include tertiary-button;
    @include p-1;
    position: absolute;
    z-index: 3;
    bottom: 0.5rem;
    right: 0.5rem;
    width: auto;
    height: auto;
    border-radius: 8px;
}

// --------------------------------------------------
// FEEDBACK BUTTON
// --------------------------------------------------

@mixin feedback-button {
    @include button-base(
        $color: var(--discoveredanswerbackground, #006AA6),
        $background-color: var(--white, #FFF),
        $border: var(--discoveredanswerstroke, #CEE1EC),
        $box-shadow: 0px 4px 0px 0px var(--discoveredanswerstroke, #CEE1EC),
        $icon-color: inherit,
    );
    @include button-extra(
        $color--hover: null,
        $background-color--hover: null,
        $border--hover: null,
        $color--active: null,
        $background-color--active: null,
        $border--active: null,
    );
}