app-memory {

    mat-card.activities-card {

        .mat-mdc-card-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}