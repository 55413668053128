// --------------------------------------------------
// STYLE
// --------------------------------------------------

html {
    font-size: $root-em; // Root element font-size
}

body {
    @include body-font;
    color: var(--text, #006AA6);
}

html,
body {
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    color: var(--text, #006AA6);
    background-color: var(--appbackground, #FFF);
}

h1,
.h1 {
    @include h1-font;
}

h2,
.h2 {
    @include h2-font;
}

h3,
.h3 {
    @include h3-font;
}

h4,
.h4 {
    @include h4-font;
}

b,
.bold {
    @include bold-font;
}

caption,
.caption {
    @include caption-font;
}

button,
.button {
    @include button-font;
    cursor: pointer;
}

a {
    cursor: pointer;
}

// --------------------------------------------------
// LAYOUT
// --------------------------------------------------

.page-layout {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

content,
.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

router-outlet + *:not(router-outlet) { // Target dynamically loaded components
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    overflow: auto;
}