app-badge-type-gallery {
    @include london-map-background--blurred;

    .badge-type-gallery {
        @include g-3;
        @include pt-2;
        @include pb-3;
        @include px-2;
        display: flex;
        flex-direction: column;
        align-items: center;

        .badge-type-gallery__back-button {
            @include mr-auto;
            text-transform: uppercase;

            span {
                transform: translateY(0.2rem);
            }
        }

        .badge-type-gallery__badge-list {
            @include g-2;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            max-width: 135rem;

            .badge-type-gallery__badge-item {
                @include secondary-button;
                @include p-3;
                position: relative;
                justify-content: center;
                width: #{set-fluid-scale(150px, 250px)};
                min-width: 15rem;
                height: auto;
                aspect-ratio: 3 / 2;
                background-color: var(--tertiarybackground, #F7E6EC);

                .badge-type-gallery__badge-image {
                    width: auto;
                    max-width: 80%;
                    height: auto;
                    max-height: 80%;
                }

                &[selected],
                &[unlocked],
                &[buyable] {
                    transition: background-color 55ms linear,
                                margin 55ms linear,
                                box-shadow 55ms linear;

                    &:hover,
                    &:focus {
                        background-color: var(--tertiarybackground--hover, #FFF2F2);
                    }

                    &:active {
                        margin-top: 4px;
                        margin-bottom: 0;
                        box-shadow: none;
                    }
                }

                &[selected] {
                    border-color: var(--primaryborder, #4B001C);
                    box-shadow: 0px 4px 0px 0px var(--primaryborder, #4B001C);

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                        background-color: var(--primaryborder, #4B001C);
                        opacity: 0.6;
                    }

                    .badge-type-gallery__check-icon {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 4rem;
                        height: auto;
                        aspect-ratio: 1 / 1;
                        transform: translate(-50%, -50%);
                        color: var(--primaryborder, #4B001C);
                        background-color: var(--white, #FFF);
                        border-radius: 50%;

                        svg {
                            width: 100%;
                            min-width: unset;
                            height: 100%;
                            min-height: unset;
                            transform: scale(1.1);
                        }
                    }
                }

                &:not([unLocked]) {

                    .badge-type-gallery__badge-data {
                        position: absolute;
                        display: flex;
                        align-items: stretch;
                        border: 2px solid var(--text, #006AA6);
                        border-radius: 6px;

                        .badge-type-gallery__badge-level,
                        .badge-type-gallery__badge-price {
                            @include px-1;
                            display: flex;
                            align-items: center;

                            .badge-type-gallery__lock-open-icon,
                            .badge-type-gallery__coin-icon {
                                flex: none;

                                svg {
                                    width: 100%;
                                    min-width: unset;
                                    height: 100%;
                                    min-height: unset;
                                }
                            }

                            span {
                                @include button-font;
                                line-height: 1.5;
                                transform: translateY(0.3rem);
                            }
                        }

                        .badge-type-gallery__badge-level {
                            background-color: #CEE1EC;
                            border-top-left-radius: inherit;
                            border-bottom-left-radius: inherit;

                            .badge-type-gallery__lock-open-icon {
                                width: 1.8rem;
                                height: auto;
                                aspect-ratio: 1 / 1;
                                color: var(--text, #006AA6);
                            }
                        }

                        .badge-type-gallery__badge-price {
                            @include g-1;
                            color: var(--white, #FFF);
                            background-color: var(--text, #006AA6);

                            .badge-type-gallery__coin-icon {
                                width: 1.4rem;
                                height: auto;
                                aspect-ratio: 1 / 1;
                            }
                        }
                    }

                    &[buyable] {

                        .badge-type-gallery__badge-data {
                            bottom: $spacing-1;
                            right: $spacing-1;
                        }
                    }

                    &:not([buyable]) {
                        background-color: var(--white, #FFF);
                        border-color: var(--disabledstroke, #52758E);
                        box-shadow: 0px 4px 0px 0px var(--disabledstroke, #52758E);
                        cursor: default;

                        .badge-type-gallery__badge-image {
                            filter: contrast(25%)
                                    grayscale(100%)
                                    sepia(100%)
                                    hue-rotate(180deg) 
                                    opacity(25%);
                        }

                        .badge-type-gallery__badge-data {
                            bottom: $spacing-1;
                            right: 50%;
                            transform: translateX(50%);
                            border: 2px solid var(--disabledstroke, #52758E);

                            .badge-type-gallery__lock-icon {
                                position: absolute;
                                top: calc(-1 * $spacing-2);
                                left: 50%;
                                width: 4rem;
                                height: auto;
                                aspect-ratio: 1 / 1;
                                overflow: visible;
                                transform: translate(-50%, -100%) rotate(15deg);
                                color: var(--disabledstroke, #52758E);

                                svg {
                                    width: 100%;
                                    min-width: unset;
                                    height: 100%;
                                    min-height: unset;
                                    transform: scale(1.1);
                                }
                            }

                            .badge-type-gallery__badge-level {
                                color: var(--textdisabled, #52758E);
                            }

                            .badge-type-gallery__badge-price {
                                background-color: var(--disabledstroke, #52758E);

                                .badge-type-gallery__coin-icon {

                                    rect:first-child {
                                        fill: #CEE1EC;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}