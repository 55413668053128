// --------------------------------------------------
// GENERIC DIALOG
// --------------------------------------------------

// ----- OVERLAY

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    background: rgba(0, 10, 16, 0.75);
    backdrop-filter: blur(2px);
}

// ----- DIALOG

mat-dialog-container.mat-mdc-dialog-container {

    .mat-mdc-dialog-surface {
        @include p-3;
        color: var(--text, #006AA6);
        background-color: var(--appbackground, #FBF5F7);
        border: 2px solid var(--progressionborder, #CEE1EC);
        border-radius: 16px;

        .mat-mdc-dialog-title,
        .mat-mdc-dialog-content,
        .mat-mdc-dialog-actions {
            padding: unset;
            color: inherit;
        }

        .mat-mdc-dialog-title {

            &::before {
                content: none; // Remove pseudo-element generated by Material
            }
        }

        .mat-mdc-dialog-content {
            max-height: 85vh;
        }

        .mat-mdc-dialog-actions {
            margin-bottom: inherit;
        }
    }
}

// --------------------------------------------------
// SELECTION DIALOG
// --------------------------------------------------

// ----- PANEL

.selection-dialog-panel {

    .mdc-dialog {

        .mdc-dialog__container {

            .mdc-dialog__surface {
                border-radius: 32px;
            }
        }
    }
}

// ----- DIALOG

fuse-selection-dialog {
    text-align: center;

    .mat-mdc-dialog-title {
        @include mb-2;

        h2 {
            @include h1-font;
            text-transform: uppercase;
            word-wrap: anywhere;
        }
    }

    .mat-mdc-dialog-content {        

        p {
            @include body-font;
            @include mb-2;
        }

        img {
            width: 100%;
        }

        form {

            .mat-mdc-form-field,
            .field-submit {
                display: flex;
                flex-direction: column;
                text-align: left;
            }

            .mat-mdc-form-field {
                @include mt-1;
                @include pb-0;
            }

            .field-submit {
                @include mx-2;
                color: var(--secondarylabel);

                .mat-icon svg {
                    fill: currentColor;
                }
            }

            button.submit {
                @include secondary-button;
                @include px-2;
                @include py-1;
                @include mt-3;
                @include mb-2;
                justify-content: space-between;
            }
        }
    }
}

// --------------------------------------------------
// DIALOG CONTAINING FULL SIZE IMAGE
// --------------------------------------------------

.fullsizeImage {
    // Variables
    $dialog-padding: $spacing-3;
    $dialog-title-height: 4rem;
    $dialog-title-margin-bottom: $spacing-2;
    // Style
    max-width: unset !important;

    .mat-mdc-dialog-surface {
        @include m-auto;
        width: auto;
        height: auto;
        padding: $dialog-padding;

        fuse-confirm-dialog {
            display: block;

            .mat-mdc-dialog-title {
                height: $dialog-title-height;
                margin-bottom: $dialog-title-margin-bottom;

                button {
                    width: auto;
                    height: inherit;
                    aspect-ratio: 1 / 1;
                }
            }

            .mat-mdc-dialog-content {
                @include m-0;
                @include p-0;
                max-height: unset;

                > div {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        object-fit: contain;
                        min-width: min(calc(50vw - ($dialog-padding * 2)), 30rem);
                        max-width: min(calc(90vw - ($dialog-padding * 2)), 150rem);
                        min-height: unset;
                        max-height: min(calc(90vh - (($dialog-padding * 2) + $dialog-title-height + $dialog-title-margin-bottom)), 150rem);

                        @media (orientation: landscape) {
                            min-width: unset;
                            min-height: min(calc(50vh - (($dialog-padding * 2) + $dialog-title-height + $dialog-title-margin-bottom)), 30rem);
                        }
                    }
                }
            }

            .mat-mdc-dialog-actions {
                display: none;
            }
        }
    }
}

// --------------------------------------------------
// HELP DIALOG
// --------------------------------------------------

app-help-lessons-dialog,
app-help-dictionary-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;

    .mat-mdc-dialog-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            padding: 10px;
        }
    }
}

// --------------------------------------------------
// FEEDBACK DIALOG
// --------------------------------------------------

app-feedback {

    .feedback {
        display: flex;
        flex-direction: column;
        gap: $spacing-3;

        .feedback__header {
            margin: unset;

            .feedback__title {
                margin: unset;
                font-size: #{set-fluid-scale(18px, 22px)};
                font-weight: 900;
                text-transform: uppercase;
            }

            .feedback__close-button {
                display: none;
            }
        }

        .feedback__content {
            margin: unset;
            padding: unset;
            font-size: #{set-fluid-scale(18px, 22px)};
            font-weight: 700;
        }

        .feedback__actions {
            padding: unset;

            .feedback__next-button {
                @include primary-button;
                width: 100%;
                min-width: 30rem;
                max-width: 40rem;
                margin: 0 auto;

                mat-icon {
                    flex: none;
                    width: 3rem;
                    height: auto;
                    aspect-ratio: 1;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}