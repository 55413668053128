app-score-indicator {
    $score-indicator-gap: 0.5rem;
    $star-icon-width: 2.5rem;

    .score-indicator {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: $score-indicator-gap;
        width: calc($star-icon-width * 3 + $score-indicator-gap * 2);
        height: 3rem;

        .score-indicator__star-icon {
            flex: none;
            width: $star-icon-width;
            height: auto;
            aspect-ratio: 1 / 1;

            svg {
                @include star-icon-colors;
                width: 100%;
                min-width: unset;
                height: auto;
                min-height: unset;
            }
        }

        .score-indicator__stars-icon,
        .score-indicator__diamond-icon {
            flex: none;
            width: auto;
            height: 100%;

            svg {
                width: 100%;
                min-width: unset;
                height: 100%;
                min-height: unset;
            }
        }

        .score-indicator__stars-icon {
            aspect-ratio: 1 / 1;

            svg {
                @include star-icon-colors--filled;
            }
        }

        .score-indicator__diamond-icon {
            aspect-ratio: 9 / 6.7;
        }

        &.score-indicator--score-step-50,
        &.score-indicator--score-step-70,
        &.score-indicator--score-step-90 {

            .score-indicator__star-icon:nth-of-type(1) {

                svg {
                    @include star-icon-colors--filled;
                }
            }
        }

        &.score-indicator--score-step-70,
        &.score-indicator--score-step-90 {

            .score-indicator__star-icon:nth-of-type(2) {

                svg {
                    @include star-icon-colors--filled;
                }
            }
        }

        &.score-indicator--score-step-90 {

            .score-indicator__star-icon:nth-of-type(3) {

                svg {
                    @include star-icon-colors--filled;
                }
            }
        }
    }
}