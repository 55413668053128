// --------------------------------------------------
// STAR ICON COLORS
// --------------------------------------------------

@mixin star-icon-colors {
    stroke: var(--progressionborder, #CEE1EC);
    fill: var(--progressionbackground, #E5EBF1);
}

@mixin star-icon-colors--filled {
    stroke: #EDA603;
    fill: #FFB800;
}