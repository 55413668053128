// Assets
$asset-base-path: '/assets/langues_app' !default;

// Typography
$root-em: 10px; // Root element font-size
$typography-font-name: 'VAGRounded';
$typography-font-name2: 'VAGRounded';
$default-font-color: var(--text);
$default-font-contrast-color: var(--primarylabel);

// Spacing (fluid scale calculator: https://www.fluid-type-scale.com)
$spacing-0: 0;
$spacing-1: clamp(5px, 0.38vw + 2.73px, 10px); // 5px to 10px
$spacing-2: clamp(10px, 0.76vw + 5.45px, 20px); // 10px to 20px
$spacing-3: clamp(20px, 1.52vw + 10.91px, 40px); // 20px to 40px
$spacing-4: clamp(40px, 3.03vw + 21.82px, 80px); // 40px to 80px
$spacing-5: clamp(80px, 6.06vw + 43.64px, 160px); // 80px to 160px
$spacing-auto: auto;
$spacing-2neg: clamp(-10px, 0.76vw + 5.45px, -20px); // -10px to -20px

$radius-0: 0;
$radius-1: clamp(8px, 0.38vw + 2.73px, 12px); // 8px to 12px
$radius-2: clamp(12px, 0.76vw + 5.45px, 26px); // 12px to 26px
$radius-3: clamp(16px, 1.52vw + 10.91px, 24px); // 16px to 24px
$radius-4: clamp(24px, 3.03vw + 21.82px, 32px); // 24px to 32px
$radius-5: clamp(32px, 6.06vw + 43.64px, 64px); // 32px to 64px
$radius-rounded: 50%;

// ========== GLOBAL ==========

:root {
    /** button **/
    --button-padding: 10px 20px;
}

// ========== APP-AWARNESS ==========

$app-awarness__without__sound__change-title-position: true;
$memory__card__back: var(--secondarylabel);

// ========== STEPPER ==========

:root {
    --stepper__button-padding: var(--button-padding);
}

$stepper__button--padding: var(--stepper__button-padding);

// ========== SUMMARY ==========

$summary--background-image: unset;
$summary--background-size: unset;
$summary--background-position: unset;
$summary--background-repeat: unset;
$summary__footer--background-color: lightgray;