app-header {

    body.is-player-active & { // = body.is-player-active app-header {}
        display: none;
    }

    .bem-header {
        // Layout
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include g-1;
        @include p-2;
        // Style
        background-color: rgba(255, 255, 255, 0.60);
        backdrop-filter: blur(5px);

        .bem-header__btn-menu {

            &.bem-header__btn-menu--margin-right { // Apply margin-right on btn-menu only if btn-help is not displayed
                margin-right: auto;
            }
        }

        .bem-header__btn-help {
            margin-right: auto;
        }

        .bem-header__btn-educational-level {

            .bem-header__educational-level-label {
                transform: translateY(0.1em);
            }
        }

        .bem-header__btn-concept {
            padding: 5px;

            img {
                width: 49px;
                height: 35px;
                border-radius: 5px;
            }

            span {
                display: none;
            }
        }

        .bem-header__btn-back {}

        .bem-header__btn-language-menu {}
    }
}