.mdc-list.mat-mdc-list-base {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 10px;
}


mat-nav-list.mat-mdc-list-base {

    button.mat-mdc-list-item,
    button.mat-mdc-list-option {

        .mdc-list-item__content {
            @include primary-button;
            flex: 1;
        }
    }
}