// --------------------------------------------------
// SIZE FIX
// --------------------------------------------------

@mixin sizeFix($width, $height: $width) {
    width: $width;
    height: $height;
    min-width: $width;
    min-height: $height;
}

@mixin iconSize($size) {
    font-size: $size;
    width: $size;
    height: $size;
    min-width: $size;
    min-height: $size;
    line-height: $size;
    svg {
        width: $size;
        height: $size;
        min-height: $size;
        min-width: $size;
    }
}
// Transforme nos boutons raised en mat-fab de taille 32px
%mat-fab-32 {
    border-radius: 50%;
    @include sizeFix(32px);
}

// --------------------------------------------------
// SVG BACKGROUND COLOR
// --------------------------------------------------

@mixin svgBackgroundColor($icon_name, $icon_color, $module: null) {
    background-color: $icon_color;
    -webkit-mask-image: icon('#{$icon_name}', $module);
    -webkit-mask-repeat: no-repeat;
    mask-image: icon('#{$icon_name}', $module);
    mask-repeat: no-repeat;
    background-repeat: no-repeat;
}

// --------------------------------------------------
// GRADIENT BORDERS
// --------------------------------------------------

// Custom gradient border
@mixin gradient-border(
    $border-width,
    $angle,
    $starting-color,
    $starting-color-stop,
    $ending-color,
    $ending-color-stop,
    $after: false
    ) {
    position: relative;

    @if $after {
        &::after { // Add a ::after pseudo-element to simulate the gradient border
            content: '';
            position: absolute;
            inset: 0;
            padding: $border-width;
            border-radius: inherit;
            background-image: linear-gradient($angle, $starting-color $starting-color-stop, $ending-color $ending-color-stop);
            mask: linear-gradient(#FFF 0 100%) content-box exclude,
                  linear-gradient(#FFF 0 100%) border-box add;
            pointer-events: none;
        }
    } @else {
        &::before { // Add a ::before pseudo-element to simulate the gradient border
            content: '';
            position: absolute;
            inset: 0;
            padding: $border-width;
            border-radius: inherit;
            background-image: linear-gradient($angle, $starting-color $starting-color-stop, $ending-color $ending-color-stop);
            mask: linear-gradient(#FFF 0 100%) content-box exclude,
                  linear-gradient(#FFF 0 100%) border-box add;
            pointer-events: none;
        }
    }
}

// Regular and reusable white to transparent border
@mixin white-to-transparent-border {
    @include gradient-border(
        $border-width: 1px,
        $angle: to bottom,
        $starting-color: rgba(255, 255, 255, 0.5),
        $starting-color-stop: 40%,
        $ending-color: transparent,
        $ending-color-stop: 80%,
    );
}

// Regular and reusable transparent to white border
@mixin transparent-to-white-border {
    @include gradient-border(
        $border-width: 1px,
        $angle: to bottom,
        $starting-color: transparent,
        $starting-color-stop: 30%,
        $ending-color: rgba(255, 255, 255, 0.5),
        $ending-color-stop: 60%,
    );
}

// Regular and reusable black to transparent border
@mixin black-to-transparent-border(
    $after-value: false
) {
    @include gradient-border(
        $border-width: 1px,
        $angle: to bottom,
        $starting-color: transparent,
        $starting-color-stop: 40%,
        $ending-color: rgba(255, 255, 255, 0.5),
        $ending-color-stop: 80%,
        $after: $after-value
    );
}

// --------------------------------------------------
// BUTTON GROUP
// --------------------------------------------------

@mixin is-group-button($group-button-position: null) {

    @if ($group-button-position == "start") {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    } @else if ($group-button-position == "middle") {
        margin-inline: 0;
        border-radius: 0;
    } @else if ($group-button-position == "end") {
        margin-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

// --------------------------------------------------
// ?
// --------------------------------------------------

default,
.invisible {
    display: none;
}