app-main-menu mat-nav-list app-main-menu-element-generic.menu-play app-main-menu-element-link a {
    flex-direction: column;
    display: flex;
    padding: 0;

    &::before {
        content: "";
        background: image('images/menu/explore-map.jpg') no-repeat bottom;
        border-radius: 10px 10px 0 0;
        height: 158px;
        width: 100%;
        background-size: 100% 100%;
    }
}

.concept-4119 app-main-menu mat-nav-list app-main-menu-element-generic.menu-play app-main-menu-element-link a::before {
    background: image('images/menu/explore-map.jpg') no-repeat bottom;
    background-size: 100% 100%;
}

.concept-4068 app-main-menu mat-nav-list app-main-menu-element-generic.menu-play app-main-menu-element-link a::before {
    background: image('images/menu/explore-map-es.jpg') no-repeat bottom;
    background-size: 100% 100%;
}

mat-sidenav app-main-menu mat-nav-list .mat-nav-list-bottom {
    background: url("/assets/langues_app/images/images/menu/girl-menu.png") no-repeat 25px 100%;
    background-size: auto 80%;
}

.assignment-count {
    background: var(--primaryborder);
    color: white;
    border-radius: 12px;
    width: 20px;
    height: 20px;
    display: inline-flex;
    justify-content: center;
    margin-left: 5px;
    padding-top: 2px;
}