app-dialog-resume {
    // Variables
    $header-height: 9rem;
    // Style
    display: flex;
    justify-content: center;
    width: 100%;
    height: calc(100vh - ($spacing-2 * 2) - $header-height - $spacing-3);

    .dialog-resume {
        @include g-2;
        width: 100%;
        min-width: 30rem;
        max-width: min(40rem, 50vh);
        height: 100%;
        display: grid;
        grid-template-rows: minmax(30rem, 1fr) auto; // https://css-tricks.com/preventing-a-grid-blowout/
        justify-items: center;

        .dialog-resume__content {
            display: grid;
            grid-template-rows: auto 1fr;
            width: 100%;
            border: 2px solid var(--progressionborder, #CEE1EC);
            border-radius: 12px;

            .dialog-resume__image-wrapper {
                overflow: hidden;
                display: flex;
                align-items: stretch; // Stretch the image child to fill its parent height
                width: 100%;
                height: auto;
                aspect-ratio: 16 / 9;
                border-bottom: 2px solid var(--progressionborder, #CEE1EC);
                border-top-left-radius: inherit;
                border-top-right-radius: inherit;

                .dialog-resume__image {
                    width: 100%; // The image element fills the entire width of its parent
                    height: auto; // Auto adjust the height of the image element depending on the original ratio of the image itself
                    max-height: 100%; // Set the maximum height of the image element to prevent it from overflowing its parent
                    object-fit: contain; // Maintain the original ratio of the image itself even if the image element doesn't
                }
            }

            .dialog-resume__actions {
                @include g-1;
                @include pt-1;
                @include px-1;
                @include pb-3;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                width: 100%;
                background-color: var(--progressionbackground, #E5EBF1);
                border-bottom-left-radius: inherit;
                border-bottom-right-radius: inherit;

                .dialog-resume__action-button-wrapper {
                    display: flex;
                    flex-direction: column;

                    .dialog-resume__action-button {
                        position: relative;

                        .dialog-resume__action-button-icon {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 2.5rem;
                            height: auto;
                            aspect-ratio: 1;
                            color: var(--primaryborder, #4B001C) !important;
                            background-color: var(--white, #FFF);
                            border: 2px solid var(--primarybackground, #b10745);
                            border-radius: 50%;
                            transform: translate(-25%, -25%);

                            svg {
                                width: 90%;
                                min-width: unset;
                                height: 90%;
                                min-height: unset;
                            }
                        }

                        .dialog-resume__action-button-label {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            quotes: '"' '"';
                            transform: translate(0.1em);
                        }

                        &.dialog-resume__action-button--playing {
                            color: var(--primarylabel--contrast, #FFF);
                            background-color: var(--primaryborder, #4B001C);
                            border-color: var(--primarybackground, #B10745);
                        }
                    }

                    app-dialog-resume-action-menu {

                        .dialog-resume-action-menu {
                            display: flex;
                            flex-direction: column;

                            .dialog-resume-action-menu__button {
                                @include primary-button;
                                transform: none !important;

                                &:not(:last-child) {
                                    margin-bottom: 0;
                                    border-radius: 0;
                                    box-shadow: none;
                                }

                                &:last-child {
                                    border-top-right-radius: 0;
                                    border-top-left-radius: 0;
                                }

                                .dialog-resume-action-menu__button-label {
                                    transform: translateY(0.1em);
                                }

                                .dialog-resume-action-menu__button-icon {
                                    flex: none;
                                    width: 3rem;
                                    height: auto;
                                    aspect-ratio: 1 / 1;

                                    svg {
                                        width: 100%;
                                        min-width: unset;
                                        height: 100%;
                                        min-height: unset;
                                    }
                                }
                            }
                        }
                    }

                    &.dialog-resume__action-button-wrapper--open {

                        .dialog-resume__action-button {
                            margin-bottom: 0;
                            border-bottom-right-radius: 0;
                            border-bottom-left-radius: 0;
                            box-shadow: none;
                            transform: none !important;
                        }
                    }
                }
            }
        }

        .dialog-resume__footer {
            @include g-2;
            @include px-1;
            width: 100%;
            display: flex;
            flex-direction: column;

            .dialog-resume__footer-button {
                @include primary-button;

                .dialog-resume__footer-button-label {
                    transform: translateY(0.1em);
                }

                .dialog-resume__footer-button-icon {
                    flex: none;
                    width: 3rem;
                    height: auto;
                    aspect-ratio: 1 / 1;

                    svg {
                        width: 100%;
                        min-width: unset;
                        height: 100%;
                        min-height: unset;
                    }
                }
            }
        }
    }
}