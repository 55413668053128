// --------------------------------------------------
// ANIMATIONS
// --------------------------------------------------

@keyframes fade-in {
    0% {
        opacity: 0;
        transform: translateY(0.4rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-in-and-reveal {
    0% {
        visibility: hidden;
        opacity: 0;
        transform: translateY(1rem);
    }
    100% {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-out-and-hide {
    0% {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        visibility: hidden;
        opacity: 0;
        transform: translateY(1rem);
    }
}

// --------------------------------------------------
// MIXINS
// --------------------------------------------------

@mixin button-animation($delay) {
    animation: fade-in 500ms ease-in-out $delay forwards;
}

// --------------------------------------------------
// STYLE
// --------------------------------------------------

app-reward-page {
    @include p-3;
    overflow: visible;
    align-items: center;

    .reward-page {
        @include g-2;
        display: grid;
        justify-items: center;
        align-items: start;
        justify-content: center;
        align-content: center;
        width: auto;
        min-width: 15rem;
        max-width: min(40rem, 35vh);
        height: 100%;

        .reward-page__reward-wrapper,
        .reward-page__level-wrapper {
            @include g-2;
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .reward-page__title {
                @include m-0;
                font-size: #{set-fluid-scale(24px, 28px)};
                text-align: center;
            }
        }

        .reward-page__reward-wrapper {

            app-dynamic {

                app-animated-canvas-in-reward-page {
                    overflow: hidden;
                    flex: none;
                    align-self: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: auto;
                    aspect-ratio: 1 / 1;

                    .animated-canvas {
                        width: 100%;
                        height: 100%;
                        transform: scale(1.4);
                    }
                }
            }

            app-end-screen-score {
                @include mb-2;
                grid-column: 1 / span 1;
                grid-row: 3 / span 1;
            }
        }

        .reward-page__level-wrapper {
            @include pb-2;
            height: 100%;
            visibility: hidden;
            opacity: 0;

            .reward-page__crown-icon {
                @include my-auto;
                flex: none;
                width: 100%;
                max-width: 20rem;
                height: auto;

                svg {
                    width: 100%;
                    min-width: unset;
                    height: 100%;
                    min-height: unset;
                }
            }
        }

        .reward-page__next-button {
            @include primary-button;
            grid-column: 1 / span 1;
            grid-row: 2 / span 1;
            justify-content: center;
            width: 100%;
            opacity: 0;
            @include button-animation(500ms); // Animation ends at 1000ms

            &.reward-page__next-button--reward-count-1 {
                @include button-animation(2000ms); // Animation ends at 2500ms
            }

            &.reward-page__next-button--reward-count-2 {
                @include button-animation(2750ms); // Animation ends at 3250ms
            }

            &.reward-page__next-button--reward-count-3 {
                @include button-animation(3500ms); // Animation ends at 4000ms
            }

            &.reward-page__next-button--reward-count-4 {
                @include button-animation(5750ms); // Animation ends at 6250ms
            }
        }

        &.reward-page--next-level {

            .reward-page__reward-wrapper {
                animation: fade-out-and-hide 500ms ease-in-out forwards;
            }

            .reward-page__level-wrapper {
                animation: fade-in-and-reveal 500ms ease-in-out 500ms forwards;
            }
        }
    }
}