app-dictionary,
app-language-lab,
app-voice-recorder-activity {

    .activities-card {

        app-voice-recorder {
            width: 100%;
            max-width: 100rem;

            .container-voice-recorder {
                display: flex;
                flex-direction: column;
                @include g-3;

                @include media-breakpoint-up('sm') {
                    flex-direction: row;
                    justify-content: center;
                }

                .pseudo-consignes {
                    flex: 1 1 50%;
                    min-width: 50%;

                    .container {
                        @include tertiary-button-base;
                        overflow: hidden;
                        border-radius: 12px;

                        .is-picture {
                            background-color: var(--white, #FFF);

                            img {
                                display: block;
                                width: 100%;
                                min-width: 25rem;
                                aspect-ratio: 16 / 9;
                                object-fit: contain;
                            }
                        }

                        .voice-recorder__caption {
                            @include py-1;
                            @include px-2;
                            @include caption-font;
                            @include button-font-weight;
                            text-transform: uppercase;
                        }
                    }
                }

                .available-answers {
                    flex: 1 1 50%;
                    display: flex;
                    flex-direction: column;
                    @include g-2;
                    max-width: 50rem;

                    .bem-voice-recorder__button {
                        min-width: max-content;
                        min-height: unset;
                        aspect-ratio: unset;
                        @include py-1;
                        @include px-2;
                    }

                    button:not(.bem-voice-recorder__button) {
                        display: none; // Do not display legacy (plateforme) button
                    }
                }
            }
        }
    }
}