app-my-assignments {
    @include g-4;
    @include p-2;

    app-no-assignment {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .content-header {
        display: flex;
        justify-content: center;

        .wrapper {
            display: flex;
            flex: auto;
            flex-direction: column;
            max-width: 800px;
        }
    }

    mat-spinner {
        @include m-auto;
    }

    app-launch-button {
        @include mt-auto;
        @include mb-3;
        align-self: center;
        position: sticky;
        z-index: 2;
        bottom: $spacing-3;
    }

    @include media-breakpoint-up('lg') {
        &::before {
            content: '';
            display: block;
            position: absolute;
            background-color: rgba(0, 106, 166, 0.15);
            top: 705px;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -2;
        }

        &::after {
            content: '';
            display: block;
            background-image: url('/assets/langues_app/images/backgrounds/perso-gauche.png'),
            url('/assets/langues_app/images/backgrounds/perso-droite.png');
            background-position: top left,
            top right;
            background-repeat: no-repeat,
            no-repeat;
            position: absolute;
            top: 200px;
            bottom: 0;
            left: calc(20px + (100vw - 1280px) / 2 * 0.7);
            right: calc((100vw - 1280px) / 2 * 0.7);
            z-index: -1;
        }
    }
}