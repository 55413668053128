.mat-mdc-form-field-type-mat-select {

    .mat-mdc-text-field-wrapper {
        padding: unset; // Unset Material style
        background-color: unset; // Unset Material style

        .mat-mdc-form-field-focus-overlay {
            display: none; // Focus overlay is handled by mat-form-field element
        }

        .mat-mdc-form-field-flex {

            .mat-mdc-form-field-infix {                

                mat-select {
                    @include primary-button;

                    .mat-mdc-select-trigger {

                        .mat-mdc-select-arrow-wrapper {
                            transform: unset; // Unset Material style

                            .mat-mdc-select-arrow {
                                color: inherit;
                            }
                        }
                    }
                }
            }
        }

        .mdc-line-ripple {
            display: none;
        }
    }
}