@use '@angular/material' as mat;
@import "abstracts/variables";
@import "app/@modules/css-library/scss/abstracts/functions";

// --------------------------------------------------
// CUSTOM COLOR MAPS (palette generator: http://mcg.mbitson.com)
// --------------------------------------------------

$md-primary: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #006AA6,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-accent: (
    50 : #f6e1e9,
    100 : #e8b5c7,
    200 : #d883a2,
    300 : #c8517d,
    400 : #bd2c61,
    500 : #b10745,
    600 : #aa063e,
    700 : #a10536,
    800 : #98042e,
    900 : #88021f,
    A100 : #ffb4bf,
    A200 : #ff8194,
    A400 : #ff4e68,
    A700 : #ff3553,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

$md-warning: (
    50 : #e0e2e2,
    100 : #b3b6b7,
    200 : #808588,
    300 : #4d5458,
    400 : #262f34,
    500 : #000a10,
    600 : #00090e,
    700 : #00070c,
    800 : #000509,
    900 : #000305,
    A100 : #4d4dff,
    A200 : #1a1aff,
    A400 : #0000e6,
    A700 : #0000cd,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

// --------------------------------------------------
// THEME CONFIGURATION
// --------------------------------------------------

// Define the Material palettes
$primary: mat.define-palette($md-primary, 500);
$accent: mat.define-palette($md-accent, 500);
$warn: mat.define-palette($md-warning, 500);

// Set up Material typography levels
$typography: mat.define-typography-config(
    // Default font family
    $font-family: '#{$typography-font-name}, Arial, sans-serif',
    // h1
    $headline-5: mat.define-typography-level(
        $font-size: #{set-fluid-scale(18px, 22px)},
        $font-weight: 700,
        $letter-spacing: normal,
        $line-height: 1.4,
    ),
    // h2
    $headline-6: mat.define-typography-level(
        $font-size: #{set-fluid-scale(16px, 20px)},
        $font-weight: 700,
        $letter-spacing: normal,
        $line-height: 1.4,
    ),
    // h3
    $subtitle-1: mat.define-typography-level(
        $font-size: #{set-fluid-scale(16px, 20px)},
        $font-weight: 400,
        $letter-spacing: normal,
        $line-height: 1.4,
    ),
    // h4
    $subtitle-2: mat.define-typography-level(
        $font-size: #{set-fluid-scale(16px, 20px)},
        $font-weight: 400,
        $letter-spacing: normal,
        $line-height: 1.4,
    ),
    // Base body text
    $body-1: mat.define-typography-level(
        $font-size: #{set-fluid-scale(14px, 20px)},
        $font-weight: 400,
        $letter-spacing: normal,
        $line-height: 1.4,
    ),
    // Bolder body text
    $body-2: mat.define-typography-level(
        $font-size: #{set-fluid-scale(14px, 20px)},
        $font-weight: 700,
        $letter-spacing: normal,
        $line-height: 1.4,
    ),
    // Smaller body text
    $caption: mat.define-typography-level(
        $font-size: #{set-fluid-scale(14px, 18px)},
        $font-weight: 400,
        $letter-spacing: normal,
        $line-height: 1.4,
    ),
    // Buttons
    $button: mat.define-typography-level(
        $font-size: #{set-fluid-scale(16px, 20px)},
        $font-weight: 700,
        $letter-spacing: normal,
        $line-height: 1.4,
    ),
);

// Create the Material theme object
$theme: mat.define-light-theme((
    color: (
        primary: $primary,
        accent: $accent,
        warning: $warn,
    ),
    typography: $typography,
    density: 0,
));

// Store the background and foreground colors for easier access
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

// Include core mixin (prerequisite styles for common features used by multiple components)
@include mat.core();

// Include custom theme mixin (must be wrapped in a selector)
html {
    @include mat.all-component-themes($theme);
}